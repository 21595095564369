<template>
  <div class="contact_detail">
    <!-- 컨텐츠 START -->
    <div class="c_section contact_detail_content">
      <div class="c_inner">
        <h2 class="text_large">
          <img src="@/assets/images/banner/icon_contact.png" alt="아이콘" />
          {{ $t(which) }}
        </h2>
        <ul class="form_list">
          <li>
            <div class="required">
              <span>{{ $t("Email") }}</span>
              <div class="input_wrapper">
                <input v-model="email" type="text" />
              </div>
            </div>
          </li>
          <li>
            <div class="required">
              <span>{{ $t("Company Name") }}</span>
              <div class="input_wrapper">
                <input v-model="company" type="text" />
              </div>
            </div>
          </li>
          <li>
            <div class="required">
              <span>{{ $t("Name") }}</span>
              <div class="input_wrapper">
                <input v-model="name" type="text" />
              </div>
            </div>
          </li>
          <li>
            <div class="required">
              <span>{{ $t("Ph Num") }}</span>
              <div class="input_wrapper">
                <input
                  v-model="contact"
                  type="text"
                  :placeholder="$t('Placeholder Num')"
                  maxlength="13"
                />
              </div>
            </div>
          </li>
          <li>
            <div class="file">
              <span
                >{{ $t("Attached Files")
                }}<span class="file_size">{{ $t("File Size") }}</span></span
              >
              <div class="input_wrapper">
                <button
                  @click="file.click()"
                  class="c_btn-common c_btn-contrast"
                >
                  {{ $t("Select") }}
                </button>
                <input
                  ref="file"
                  @change="fileSelected($event)"
                  type="file"
                  style="display: none; width: 0"
                />
                <p>{{ fileName }}</p>
              </div>
            </div>
          </li>
          <li>
            <div>
              <span>{{ $t("Comment") }}</span>
              <div class="input_wrapper">
                <textarea
                  v-model="contents"
                  :placeholder="$t('Write Here')"
                ></textarea>
              </div>
            </div>
          </li>
        </ul>
        <div class="terms_text">
          <p class="title">{{ $t("Terms Tit") }}</p>
          <p class="sub_text">{{ $t("Terms Txt") }}</p>
          <ul>
            <li>{{ $t("Terms 01") }}</li>
            <li>{{ $t("Terms 02") }}</li>
            <li>{{ $t("Terms 03") }}</li>
          </ul>
          <div class="checkbox_wrapper">
            <input v-model="agree" type="checkbox" id="agree" />
            <label for="agree">{{ $t("Agreement") }}</label>
          </div>
        </div>
        <div class="btn_wrapper">
          <button
            @click="submit"
            class="c_btn-common c_btn-contrast btn_submit"
          >
            등록
          </button>
        </div>
      </div>
    </div>
    <!-- 컨텐츠 END -->
  </div>
</template>

<script>
import { phoneFormat } from "@/utils/phoneFormat";
import axios from "axios";
// import { prop } from "dom7";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  props: {
    which: String,
  },
  setup(props) {
    const { t } = useI18n();

    const email = ref("");
    const company = ref("");
    const name = ref("");
    const contact = ref("");
    const file = ref({});
    const fileData = ref();
    const contents = ref("");
    const agree = ref("");
    const fileName = ref("");

    const store = useStore();

    const fileSelected = (event) => {
      // 파일이 존재하는지
      if (event.target.files && event.target.files[0]) {
        // 2MB보다 크면 안받도록
        if (event.target.files[0].size > 1048576 * 10) {
          // this.$store.commit("alert", "10MB 이하의 파일을 선택해 주세요");
          store.commit("alert", t("File Size"));
          // this.$store.commit("alert", JSON.stringify($t('Select')));
        } else {
          fileData.value = event.target.files[0];
          fileName.value = fileData.value.name;
        }
      }
    };

    const submit = () => {
      if (!email.value || !company.value || !name.value || !contact.value) {
        store.commit("alert", t("Alert Fill"));
      } else if (!agree.value) {
        store.commit("alert", t("Alert Agreement Privacy"));
      } else {
        const formData = new FormData();
        formData.append("site", "dever");
        formData.append("division", props.which);
        formData.append("email", email.value);
        formData.append("company", company.value);
        formData.append("name", name.value);
        formData.append("contact", contact.value);
        formData.append("file", fileData.value);
        formData.append("contents", contents.value);
        axios
          .post("/sendMail", formData, {
            headers: {
              identity: "DEVER_API",
              authorization: "dcs_fd70bee5fc6b0cefd8dcc72c59a41ba2",
            },
          })
          .then((res) => {
            if (res.data.success) {
              store.commit("alert", t("Alert Sent Inquiry"));
            } else {
              store.commit("alert", t("Alert Error Ask"));
            }
            email.value = "";
            company.value = "";
            name.value = "";
            contact.value = "";
            fileData.value = null;
            fileName.value = "";
            contents.value = "";
            agree.value = false;
          })
          .catch((error) => {
            console.log(error);
            store.commit("alert", t("Alert Error Ask"));
          });
      }
    };

    watch(contact, () => {
      contact.value = phoneFormat(contact.value);
    });

    return {
      email,
      company,
      name,
      contact,
      file,
      fileName,
      contents,
      agree,
      fileSelected,
      submit,
    };
  },
};
</script>

<style scoped src="@/assets/css/contact.css"></style>
